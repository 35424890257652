import { Link } from '@remix-run/react'
import React from 'react'
import P2 from '~/components/typography/P2'
import type { HomeViewTrans } from '~/components/views/HomeView'
import type { Language } from '~/i18n.universal'

interface Item {
  title: string
  link: string
}

interface Props {
  lang: Language
  trans: HomeViewTrans
  className?: string
}

const WhatWeVeDone: React.FC<Props> = ({ className, lang, trans }) => {
  const data = [
    {
      title: 'Capella Sydney, Australia',
      link: '/en/consultancy/project/all/Capella-Sydney/',
    },
    {
      title: 'Mondrian, Singapore',
      link: '/en/consultancy/project/all/Mondrian/',
    },
    {
      title: 'Patina Hotel, Maldives',
      link: '/en/consultancy/project/all/Patina-Hotel/',
    },
    {
      title: 'Ritz-Carlton Hotel, Maldives',
      link: '/en/consultancy/project/all/Ritz-Carlton-Hotel/',
    },
    {
      title: 'Swarowski, China',
      link: '/en/consultancy/project/all/swarovski/',
    },
    {
      title: 'Frasers Tower, Singapore',
      link: '/en/consultancy/project/all/frasers-tower/',
    },
  ]

  const renderItem = (item: Item) => {
    return (
      <Link
        className="mb-3"
        to={item.link}
        prefetch="intent"
      >
        <P2 className="whitespace-nowrap">{item.title}</P2>
      </Link>
    )
  }

  return (
    <div
      className={
        'flex w-full items-start justify-between gap-6' +
        (className ? ` ${className}` : '')
      }
    >
      <P2 className="whitespace-nowrap">{trans.WhatWeVeDone}</P2>
      <div className="mt-[0.5em] w-full border-0 border-t border-gray-border"></div>
      <div className="gap-6 md:flex">
        <div>
          {data.slice(0, 3).map((item, index) => (
            <React.Fragment key={index}>{renderItem(item)}</React.Fragment>
          ))}
        </div>
        <div>
          {data.slice(3, 6).map((item, index) => (
            <React.Fragment key={index}>{renderItem(item)}</React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default WhatWeVeDone
