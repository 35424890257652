// React imports
import React from 'react'

// Remix imports

// Third party imports

// Generated imports
import type { ImageFragment, Maybe } from '~/generated/graphql'

// App imports
import { Link } from '@remix-run/react'
import type { HomeViewTrans } from '~/components/views/HomeView'
import useImagekit from '~/hooks/useImagekit'
import urlReverse from '~/utils/urlReverse'

interface Props {
  data?: Maybe<ImageFragment>
  trans: HomeViewTrans
  className?: string
}

const AsFeaturedIn: React.FC<Props> = ({ className, data, trans }) => {
  const { imagekitUrl } = useImagekit()
  return (
    <div className={className + ' flex justify-center'}>
      <Link to={urlReverse('pressList', { lang: 'en' })}>
        <img
          className="hidden w-full md:block md:max-w-[760px]"
          alt={trans.MediaPublicationLogos}
          src={imagekitUrl(data?.originalImage, '?tr=w-2220')}
        />
        {data?.mobileImage && (
          <img
            className="block w-full md:hidden"
            alt={trans.MediaPublicationLogos}
            src={imagekitUrl(data?.mobileImage, '?tr=w-1424')}
          />
        )}
      </Link>
    </div>
  )
}

export default AsFeaturedIn
