// React Imports
import React from 'react'

// Remix Imports
import type { Params } from '@remix-run/react'
import { useLoaderData } from '@remix-run/react'

// Third Party imports
import gql from 'graphql-tag'

// Generated imports
import type { HomeViewQuery } from '~/generated/graphql'

// App imports
import AsFeaturedIn from '~/components/banners/AsFeaturedIn'
import SubmitToTheartling from '~/components/banners/SubmitToTheartling'
import BannerCarousel from '~/components/carousels/BannerCarousel'
import H1WithArrowLink from '~/components/links/H1WithArrowLink'
import ArtzineTeaser from '~/components/sections/ArtzineTeaser'
import BrowseByStyle from '~/components/sections/BrowseByStyle'
import type { JoinOurTradeProgramTrans } from '~/components/sections/JoinOurTradeProgram'
import JoinOurTradeProgram, {
  getJoinOurTradeProgramTrans,
} from '~/components/sections/JoinOurTradeProgram'
import NewInDesign from '~/components/sections/NewInDesign'
import type { SelectedProjectsTrans } from '~/components/sections/SelectedProjects'
import SelectedProjects, {
  getSelectedProjectsTrans,
} from '~/components/sections/SelectedProjects'
import Trending from '~/components/sections/Trending'
import WhatWeVeDone from '~/components/sections/WhatWeVeDone'
import H1Lead from '~/components/typography/H1Lead'
import H2 from '~/components/typography/H2'
import HR from '~/components/typography/HR'
import { fetchGQL } from '~/fetch.server'
import { Banner as BannerFragment } from '~/fragments/BannerType'
import { BlogPage } from '~/fragments/BlogPageType'
import { ConsultancyProject } from '~/fragments/ConsultancyProjectType'
import { GlobalData } from '~/fragments/GlobalDataType'
import { ImageGroupImage } from '~/fragments/ImageGroupImageType'
import { Image } from '~/fragments/ImageType'
import type { PickTranslations } from '~/i18n.server'
import { getTranslations } from '~/i18n.server'
import { transArray, type Language } from '~/i18n.universal'
import { CACHE_CONTROL } from '~/utils/cacheControl'
import type { GlobalDataAndTrans } from '~/utils/getGlobalDataAndTrans.server'
import { loaderJson } from '~/utils/jsonResponse.server'
import urlReverse from '~/utils/urlReverse'
import AIStrip from '../sections/AIStrip'

export const HOME_VIEW_CACHE = CACHE_CONTROL.ONE_HOUR

// ============================================================================
// HTML =======================================================================
// ============================================================================
const HomeView: React.FC = () => {
  const loaderData = useLoaderData()
  const { data, trans, joinOurTradeProgramTrans, selectedProjectsTrans, lang } =
    loaderData as HomeViewLoaderData

  return (
    <main>
      <H1Lead className="max-w-[420px] md:max-w-[750px]">
        {trans.TransformingSpacesWithArtAndDesign}
      </H1Lead>

      <BannerCarousel
        className="mt-next-group-element-2x"
        data={data.pagesHomeView.banners}
      />

      <AIStrip
        lang={lang}
        trans={trans}
        BGImg={data.pagesHomeView.discoveryImg || ''}
        className="mt-next-group-element-2x "
      />

      <H1WithArrowLink
        className="mt-next-group-element block"
        text={trans.DiscoverCuratedArtAndDesign}
        link={urlReverse('productArtShopGrid', { lang })}
      />

      <WhatWeVeDone
        className="mt-next-section"
        lang={lang}
        trans={trans}
      />

      <Trending
        className="mt-next-section"
        data={data.pagesHomeView.trending}
        trans={trans}
        lang={lang}
      />

      <JoinOurTradeProgram
        className="mt-next-section"
        lang={lang}
        trans={joinOurTradeProgramTrans}
        images={data.pagesHomeView.joinTradeImages}
      />

      <SelectedProjects
        className="mt-next-section"
        lang={lang}
        trans={selectedProjectsTrans}
        data={data.pagesHomeView.selectedProjects}
        imageFieldName="featuredOnHomepageImage"
      />

      <H2 className="mt-next-section text-center">{trans.OurClients}</H2>
      <AsFeaturedIn
        className="mt-next-element"
        data={data.pagesHomeView.asFeaturedInImg}
        trans={trans}
      />

      <HR className="mt-next-section" />

      <BrowseByStyle
        className="mt-next-section"
        data={data.pagesHomeView.byStyle}
        trans={trans}
        lang={lang}
      />

      <NewInDesign
        className="mt-next-section"
        data={data.pagesHomeView.newInDesign}
        trans={trans}
        lang={lang}
      />

      <HR className="mt-next-section" />

      <ArtzineTeaser
        className="mt-next-section"
        data={data.pagesHomeView.artzine}
        trans={trans}
      />

      <SubmitToTheartling
        data={data.pagesHomeView.submitImg}
        trans={trans}
        className="mt-next-section"
      />
    </main>
  )
}

export default HomeView

// ============================================================================
// TRANS ======================================================================
// ============================================================================
const transArr = transArray([
  'Artzine',
  'AsFeaturedIn',
  'AuthenticityGuaranteed',
  'AuthenticityGuaranteedText',
  'Beta',
  'BetaAccessExclusiveForTradeUsers',
  'BrowseByArtworkPrice',
  'BrowseByArtworkStyle',
  'BrowseTrendingArtworks',
  'ComingSoonNFTS',
  'ConciergeService',
  'ConciergeServiceText',
  'CuratedArtAndDesign',
  'CuratedArtAndDesignText',
  'DiscoverCuratedArtAndDesign',
  'DiscoverMoreProjects',
  'Error',
  'Explore',
  'LatestArtNews',
  'Loading',
  'MediaPublicationLogos',
  'NewInDesign',
  'OurClients',
  'TheArtlingAIDiscoverySearch',
  'ProductByX',
  'SelectedProjects',
  'SubmitToTheArtling',
  'SubmitToTheArtlingText',
  'TheArtlingDiscoverySearch',
  'TransformingSpacesWithArtAndDesign',
  'Trending',
  'TrendingArtworks',
  'ViewAllArtNews',
  'Welcome',
  'WhatWeVeDone',
])

export type HomeViewTrans = PickTranslations<(typeof transArr)[number]>

export const getHomeViewTrans = (lang: Language) => {
  return getTranslations(lang, transArr)
}

// ============================================================================
// LOADER (actually called in $lang.index.tsx)=================================
// ============================================================================
export const HOME_VIEW_QUERY = gql`
  ${BannerFragment}
  ${BlogPage}
  ${GlobalData}
  ${ImageGroupImage}
  ${Image}
  ${ConsultancyProject}
  query HomeView($cohort: String) {
    pagesHomeView {
      status
      joinTradeImages
      discoveryImg
      selectedProjects {
        ...ConsultancyProject
      }
      banners {
        ...Banner
      }
      asFeaturedInImg {
        ...Image
      }
      submitImg {
        ...Image
      }
      byStyle {
        ...ImageGroupImage
      }
      byPrice {
        ...ImageGroupImage
      }
      newInDesign {
        ...ImageGroupImage
      }
      trending {
        ...ImageGroupImage
      }
      artzine(cohort: "buyer") {
        ...BlogPage
      }
      globalData {
        ...GlobalData
      }
    }
  }
`

export interface HomeViewLoaderData {
  data: HomeViewQuery
  lang: Language
  trans: HomeViewTrans
  joinOurTradeProgramTrans: JoinOurTradeProgramTrans
  selectedProjectsTrans: SelectedProjectsTrans
  global: GlobalDataAndTrans
}

export let loaderFunc = async (
  request: Request,
  params: Params,
  lang: Language
) => {
  const trans = getHomeViewTrans(lang)
  const joinOurTradeProgramTrans = getJoinOurTradeProgramTrans(lang)
  const selectedProjectsTrans = getSelectedProjectsTrans(lang)

  const { status, data, headers, global } = await fetchGQL<HomeViewQuery>(
    request,
    params,
    HOME_VIEW_QUERY,
    {}
  )

  return loaderJson(
    {
      lang,
      trans,
      joinOurTradeProgramTrans,
      selectedProjectsTrans,
      data,
      global,
    },
    HOME_VIEW_CACHE,
    {
      status,
      headers,
    }
  )
}
