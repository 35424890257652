// React imports
import React from 'react'

// Remix imports

// Third party imports

// Generated imports
import type { ImageGroupImageFragment } from '~/generated/graphql'

// App imports
import { Link } from '@remix-run/react'
import Button from '~/components/buttons/Button'
import type { FourItemsSwiperItem } from '~/components/carousels/FourItemsGridSwiper'
import FourItemsGridSwiper from '~/components/carousels/FourItemsGridSwiper'
import H2 from '~/components/typography/H2'
import type { HomeViewTrans } from '~/components/views/HomeView'
import type { Language } from '~/i18n.universal'
import urlReverse from '~/utils/urlReverse'

interface Props {
  lang: Language
  data: ImageGroupImageFragment[]
  trans: HomeViewTrans
  className?: string
}

const Trending: React.FC<Props> = ({ lang, className, data, trans }) => {
  const swiperData = data.map((item) => {
    return {
      image: item.image?.originalImage,
      link: item.url,
      title: item.mainText,
      subtitle: item.secondaryText,
    }
  }) as FourItemsSwiperItem[]

  return (
    <div className={className}>
      <H2 className="hidden text-center md:block">
        {trans.BrowseTrendingArtworks}
      </H2>
      <H2 className="block text-center md:hidden">{trans.TrendingArtworks}</H2>

      <FourItemsGridSwiper
        data={swiperData.slice(0, 4)}
        className="mt-6"
      />

      <div className="mt-6 flex w-full justify-center">
        <Link
          className="w-full max-w-[400px]"
          to={urlReverse('productArtShopGrid', { lang })}
          prefetch="intent"
        >
          <Button
            size="large"
            className="w-full"
          >
            {trans.Explore}
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default Trending
