import React from 'react'
import Swiper from '~/components/carousels/Swiper'
import ImageOrVideo from '~/components/images/ImageOrVideo'
import LinkOrInternalLinkOrDiv from '~/components/links/LinkOrInternalLinkOrDiv'
import P1 from '~/components/typography/P1'
import P2 from '~/components/typography/P2'

export interface FourItemsSwiperItem {
  image: string
  link?: string
  title: string
  subtitle?: string
}

interface Props {
  data: FourItemsSwiperItem[]
  className?: string
}

const FourItemsGridSwiper: React.FC<Props> = ({ className, data }) => {
  const elements = data.map((item, index) => {
    return (
      <React.Fragment key={index}>
        <div className="aspect-square w-full">
          <LinkOrInternalLinkOrDiv
            to={item.link}
            className="w-full"
          >
            <ImageOrVideo
              alt={item.title}
              className="w-full"
              transformation={{
                width: `${260 * 2}`,
                height: `${260 * 2}`,
                bg: 'FFFFFF',
              }}
              path={item.image}
              maxWidth={260}
              maxHeight={260}
            />
          </LinkOrInternalLinkOrDiv>
        </div>
        <P1 className="mt-3 overflow-hidden text-ellipsis whitespace-nowrap font-serif font-bold">
          {item.title}
        </P1>
        {item.subtitle && (
          <P2 className="overflow-hidden text-ellipsis whitespace-nowrap text-brand-gold">
            {item.subtitle}
          </P2>
        )}
      </React.Fragment>
    )
  })

  return (
    <div className={className}>
      <Swiper
        items={elements}
        gridCols="md:grid-cols-4"
      />
    </div>
  )
}

export default FourItemsGridSwiper
