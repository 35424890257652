// React imports
import React from 'react'

// Remix imports

// Third party imports

// Generated imports
import type { ImageGroupImageFragment } from '~/generated/graphql'

// App imports
import type { FourItemsSwiperItem } from '~/components/carousels/FourItemsGridSwiper'
import FourItemsGridSwiper from '~/components/carousels/FourItemsGridSwiper'
import H2 from '~/components/typography/H2'
import type { HomeViewTrans } from '~/components/views/HomeView'
import type { Language } from '~/i18n.universal'

interface Props {
  data: ImageGroupImageFragment[]
  trans: HomeViewTrans
  className?: string
  lang: Language
}

const NewInDesign: React.FC<Props> = ({ lang, className, data, trans }) => {
  if (data.length !== 4) {
    return null
  }

  const swiperData = data.map((item) => {
    return {
      image: item.image?.originalImage,
      link: `/${lang}${item.url}`,
      title: item.mainText,
      subtitle: item.secondaryText,
    }
  }) as FourItemsSwiperItem[]

  return (
    <div className={className}>
      <H2 className="text-center font-serif">{trans.NewInDesign}</H2>

      <FourItemsGridSwiper
        data={swiperData.slice(0, 4)}
        className="mt-6"
      />
    </div>
  )
}

export default NewInDesign
