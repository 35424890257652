// React imports
import React from 'react'

// Remix imports

// Third party imports

// Generated imports
import type { BlogPageFragment } from '~/generated/graphql'

// App imports
import Swiper from '~/components/carousels/Swiper'
import ImageOrVideo from '~/components/images/ImageOrVideo'
import H1WithArrowLink from '~/components/links/H1WithArrowLink'
import InternalLink from '~/components/links/InternalLink'
import H2 from '~/components/typography/H2'
import H3 from '~/components/typography/H3'
import P2 from '~/components/typography/P2'
import type { HomeViewTrans } from '~/components/views/HomeView'
import useLang from '~/hooks/useLang'
import urlReverse from '~/utils/urlReverse'

interface Props {
  data: BlogPageFragment[]
  trans: HomeViewTrans
  className?: string
}

const ArtzineTeaser: React.FC<Props> = ({ className, data, trans }) => {
  const lang = useLang()
  const elements = data.map((item, index) => {
    let dateString = ''
    if (item?.dateDict) {
      dateString = `${item.dateDict.monthStr} ${item.dateDict.day}, ${item.dateDict.year}`
    }
    return (
      <div
        key={index}
        className="text-left"
      >
        <InternalLink
          to={item.detailUrl || ''}
          className="hover:no-underline"
        >
          <div className="aspect-499/380 w-full">
            <ImageOrVideo
              alt={item.title || ''}
              className="w-full hover:opacity-70"
              transformation={{
                width: `${499 * 2}`,
                height: `${380 * 2}`,
                bg: 'FFFFFF',
              }}
              path={item.headerImage?.image || ''}
              maxWidth={499}
              maxHeight={380}
            />
          </div>
          <P2 className="mt-3 text-brand-gold">
            {dateString}
            {item.author && ` by ${item.author}`}
          </P2>
          <H3 className="mt-1">{item.title}</H3>
        </InternalLink>
      </div>
    )
  })

  return (
    <div className={className}>
      <InternalLink to={urlReverse('artzineList', { lang })}>
        <H2 className="text-center">{trans.Artzine}</H2>
      </InternalLink>
      <Swiper
        items={elements}
        gridCols="md:grid-cols-3"
        className="mt-6"
      />
      <H1WithArrowLink
        className="mt-next-group-element block"
        text={trans.ViewAllArtNews}
        link={urlReverse('artzineList', { lang })}
      />
    </div>
  )
}

export default ArtzineTeaser
