import gql from 'graphql-tag'

export const Banner = gql`
  fragment Banner on NewBannerType {
    id
    description
    link
    order
    originalImage
    originalImageMobile
    title
  }
`
