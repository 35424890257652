// React imports
import React from 'react'

// Remix imports

// Third party imports

// Generated imports
import type { ImageGroupImageFragment } from '~/generated/graphql'

// App imports
import StyleSlider from '~/components/carousels/StyleSlider'
import H2 from '~/components/typography/H2'
import type { HomeViewTrans } from '~/components/views/HomeView'
import type { Language } from '~/i18n.universal'

interface Props {
  data?: ImageGroupImageFragment[]
  trans: HomeViewTrans
  className?: string
  lang: Language
}

const BrowseByStyle: React.FC<Props> = ({ lang, className, data, trans }) => {
  if (!data) {
    return null
  }

  const sliderData = data.map((item) => ({
    image: item.image?.originalImage || '',
    link: item.url || '',
    title: item.mainText || '',
  }))

  return (
    <div className={className}>
      <H2 className="text-center">{trans.BrowseByArtworkStyle}</H2>

      <StyleSlider
        lang={lang}
        className="mt-14"
        data={sliderData}
      />
    </div>
  )
}

export default BrowseByStyle
