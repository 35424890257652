// React imports
import React from 'react'

// Remix imports

// Third party imports

// Generated imports
import type { ImageFragment } from '~/generated/graphql'

// App imports
import InternalLink from '~/components/links/InternalLink'
import type { HomeViewTrans } from '~/components/views/HomeView'
import useImagekit from '~/hooks/useImagekit'
import useLang from '~/hooks/useLang'
import urlReverse from '~/utils/urlReverse'

interface Props {
  data?: ImageFragment | null
  trans: HomeViewTrans
  className?: string
}

const SubmitToTheartling: React.FC<Props> = ({ className, data, trans }) => {
  const { imagekitUrl } = useImagekit()
  const lang = useLang()

  if (!data) {
    return null
  }

  return (
    <InternalLink
      // TODO links: replace this when /en/submissions/ SubmissionsView is in.
      className={className + ' block hover:opacity-hover'}
      to={`${urlReverse('submissions', { lang })}`}
      prefetch="intent"
    >
      <img
        className="hidden w-full md:block"
        alt={`${trans.SubmitToTheArtling}. ${trans.SubmitToTheArtlingText}`}
        src={imagekitUrl(data.originalImage, '?tr=w-2220')}
      />
      <img
        className="block w-full md:hidden"
        alt={`${trans.SubmitToTheArtling}. ${trans.SubmitToTheArtlingText}`}
        src={imagekitUrl(data.mobileImage, '?tr=w-1423')}
      />
    </InternalLink>
  )
}

export default SubmitToTheartling
