import { Link } from '@remix-run/react'
import React from 'react'
import ScaleText from 'react-scale-text'
import Slider from '~/components/carousels/Slider'
import ImageOrVideo from '~/components/images/ImageOrVideo'
import P2 from '~/components/typography/P2'
import type { Language } from '~/i18n.universal'

interface StyleSliderItem {
  image: string
  link: string
  title: string
}

interface Props {
  lang: Language
  data: StyleSliderItem[]
  className?: string
}

export const Dummy: React.FC = () => {
  return <div className="scaletext-wrapper"></div>
}

const StyleSlider: React.FC<Props> = ({ lang, data, className }) => {
  const items = data.map((item, index) => {
    return (
      <Link
        to={`/${lang}/art/${item.link}/`}
        key={index}
        className="relative mt-0 block aspect-454/380 w-full max-w-[454px]"
      >
        <ImageOrVideo
          alt={item.title}
          className="w-[100%]"
          transformation={{
            width: `${454 * 2}`,
            height: `${380 * 2}`,
            cropMode: 'pad_resize',
            bg: 'FAF8F4',
          }}
          path={item.image}
          maxWidth={454}
          maxHeight={380}
        />
        <div className="absolute top-0 flex h-full w-full items-center justify-center">
          <div className="w-full">
            <div className="hidden h-full w-full md:block">
              <ScaleText
                maxFontSize={110}
                widthOnly={true}
              >
                <span className="inline-block w-full whitespace-nowrap text-center font-serif leading-[1em]">
                  {item.title}
                </span>
              </ScaleText>
              <P2 className="text-center underline">Click to view</P2>
            </div>

            <div className="block h-full w-full md:hidden">
              <ScaleText
                maxFontSize={80}
                widthOnly={true}
              >
                <span className="inline-block w-full whitespace-nowrap text-center font-serif leading-[1em]">
                  {item.title}
                </span>
              </ScaleText>
              <P2 className="text-center underline">Click to view</P2>
            </div>
          </div>
        </div>
      </Link>
    )
  })

  return (
    <div className={className}>
      <Slider
        slides={items}
        aspectRatio="aspect-454/380"
        maxWidth={454}
      />
    </div>
  )
}

export default StyleSlider
